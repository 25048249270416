// src/components/Footer.js
import React from 'react';
import "../App.css";

function Footer({ handleMoveToComponent }) {
  return (
    <footer style={{}} className="footer">
  <div className="footer-content">
    <p>&copy; {new Date().getFullYear()} Jordan Jancic. All rights reserved.</p>
    <div className="footer-links">
      <a 
        href="https://www.linkedin.com/in/jordan-jancic-a0b899b9/" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        LinkedIn
      </a>
      <a 
        href="https://github.com/JordanJancic" 
        target="_blank" 
        rel="noopener noreferrer"
      >
        GitHub
      </a>
      <a 
        href="#contact" 
        onClick={(e) => {
          e.preventDefault(); // Prevent default anchor behavior
          handleMoveToComponent(3); // Call the function to scroll to the contact section
        }}
        style={{ cursor: 'pointer' }} // Add cursor style here if needed
      >
        Contact
      </a>
    </div>
  </div>
</footer>


  );
};

export default Footer;
