import logo from "./logo.svg";
import "./App.css";
import { useState, useEffect } from "react";
import "./fonts/fonts.css";
import NavBar from "./components/NavBar";
import BasicNewsArticle from "./components/BasicNewsArticle";
import GlassContainer from "./components/GlassContainer";
import ProjectItem from "./components/ProjectItem";
import Footer from "./components/Footer";
import { FaMoon, FaSmile } from "react-icons/fa";
import { FaReact } from "react-icons/fa";
import { FaPython } from "react-icons/fa";
import { FaJava } from "react-icons/fa";

import { FaHouseUser } from "react-icons/fa";
import { FaFlushed } from "react-icons/fa";
import { FaProjectDiagram } from "react-icons/fa";
import { FaPaperPlane } from "react-icons/fa";

import { FaSun } from "react-icons/fa";
import { FaLightbulb } from "react-icons/fa";
import zIndex from "@mui/material/styles/zIndex";
import { BorderAllTwoTone, FullscreenExitTwoTone } from "@mui/icons-material";
import bgImage from "./images/bg1.png";
import bgImage2 from "./images/bg2.jpg";
import bgImage3 from "./images/bg3.jpg";
import bgImage4 from "./images/bg4.jpg";
import bgImage5 from "./images/bg6.png";
import bgImage6 from "./images/bg6.png";
import bgImage7 from "./images/bg7.jpg";
import bgImage8 from "./images/bg8.jpg";
import bgImage9 from "./images/bg9.jpg";
import bgImage10 from "./images/bg10.png";
import bgImage11 from "./images/bg11.jpg";
import bgImage12 from "./images/bg12.jpg";
import bgImage13 from "./images/bg13.jpg";
import jancic from "./images/jancic.jpg";

import { fetchNewsArticles, fetchPortfolioProjects } from './api/dataService'; // Adjust path if needed
import { colors } from "@mui/material";
import BottomTabNavigator from "./components/BottomTabNavigator";


function App() {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Adjust the threshold as needed
  const [theme, setTheme] = useState("dark");

  const [newsArticles, setNewsArticles] = useState([]);

  const handleResize = () => {
    const isCurrentlyMobile = window.innerWidth < 768;
    setIsMobile(isCurrentlyMobile);

  };

  const handleMoveToComponent = (value) => {
    
    const sectionIds = ["home", "projects", "about", "contact"];
    // console.log(sectionIds[value]);
    // Get the corresponding section element by ID
    const targetSection = document.getElementById(sectionIds[value]);
  
    if (targetSection) {
      // Scroll smoothly to the target section
      targetSection.scrollIntoView({ behavior: 'smooth' });
    }
  };
  

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    fetchNewsArticles()
      .then((response) => {
        // Assuming the response data is an object with a 'status' field
        if (response.status === 'OK') {
          // console.log(response.data); // Log the data instead of the whole response
          setNewsArticles(response.data);
        } else {
          console.log(`Error getting news articles:, ${response.status}`);
        }
      })
      .catch((error) => {
        // Handle errors from the fetchNewsArticles function
        console.log(`Error getting news articles:, ${error}`);
      });
  }, []);
  

  const [sectionsList, setSectionsList] = useState([
    { title: "Title", description: "Description" },
  ]);

  const handleToggleTheme = () => {
    if (theme === "dark") {
      setTheme("light");
    } else {
      setTheme("dark");
    }
  };

  return (
    
    <div
     
      className="App"
      style={{
        height: '100%',
        width: '100%',
        // backgroundImage: 'url(https://img.freepik.com/premium-photo/neon-cyberpunk-city-urban-future-metaverse-night-purple-street-texture-background_910135-7584.jpg?w=826)',
        // backgroundImage: `url(${bgImage})`,
        // backgroundImage: 'url(https://w.wallhaven.cc/full/p8/wallhaven-p8e95j.jpg)',
        // backgroundImage: `url(${bgImage10})`,
        backgroundColor: 'black',
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        position: "absolute",
        overflow: "hidden",
        // backgroundColor: theme === "dark" ? "#0d0d1c" : "#ffffff",
        // border: "1px solid red",
        zIndex: 0,
        overflowY: "scroll",
        position: 'absolute'
      }}
    >
      <div
      id={'home'}
        style={{
          // border: '3px solid purple',
          height: 500,
          width: "100%",
          position: "absolute",
          background: "linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(14, 14, 33, 0.6) 70%, rgba(25, 23, 53, 1) 100%)",
          zIndex: -1,
        }}
      />

      <div
      
        style={{
          marginTop: 40,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          pointerEvents: "none",
          zIndex: 2,
          border: "0px solid GREEN",
        }}
      >
        <div
         
          style={{
            border: "0px solid red",
            display: "flex",
            flexDirection: "row",
            width: "80%",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <h1
            style={{
              fontSize: 40,
              color: theme === "light" ? "#5a59c9" : "white",
              userSelect: "none",
            }}
          >
            JORDAN
          </h1>
          <h1
            className="bolden-gate"
            style={{
              fontSize: 40,
              color: theme === "light" ? "#5a59c9" : "#6e6ef4",
              userSelect: "none",
            }}
          >
            JANCIC
          </h1>
        </div>
        <div
          style={{
            border: "0px solid red",
            display: "flex",
            flexDirection: "row",
            width: "80%",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <p
            style={{
              lineHeight: 0,
              marginRight: "auto",
              color: theme === "light" ? "#5a59c9" : "white",
              userSelect: "none",
            }}
          >
            Learn.&nbsp;Adapt.&nbsp;Develop.&nbsp;
          </p>
        </div>
      </div>
      {!isMobile && (
        <NavBar handleMoveToComponent={(value) => handleMoveToComponent(value)} theme={theme} handleToggleTheme={() => handleToggleTheme()} />
      )}
      

      <div style={{height: 45}} />
      
      <div id={'projects'} style={{width: '100%', display: 'flex', justifyContent: 'center', borderWidth: 0,}}>
      <GlassContainer disabled={isMobile} style={{display: "flex", flexDirection: 'column', }} theme={theme}>
        <div style={{borderWidth: 0, width: '100%', flexDirection: 'row', display: 'flex', justifyContent: 'center'}}>
         {/* <h1
            style={{
              fontSize: 30,
              color: theme === "light" ? "#5a59c9" : "white",
              userSelect: "none",
            }}
          >
            PROJECT&nbsp;
          </h1> */}
          <div
            style={{width: '100%', marginTop: 35}}
          >
            <h1
              className="bolden-gate"
              style={{
                fontSize: 22,
                // color: theme === "light" ? "#5a59c9" : "#98FF98",
                color: theme === "light" ? "#5a59c9" : "#6e6ef4",
                userSelect: "none",
                textAlign: !isMobile ? 'left' : 'left',
                // padding: !isMobile ? 30 : 0,
                // paddingLeft: !isMobile ? 70 : 0,
                paddingTop: !isMobile ? 35 : 20,
                padding: isMobile ?  '0px 30px' : '0px 300px',
                paddingBottom: 10,
                // letterSpacing: 15,
                // border: '1px solid white'
              }}
            >
              PROJECTS
          </h1>
          <div style={{width: '100%', border: '0px solid red', paddingBottom: 20}}>
          <p
              // className="bolden-gate"
              style={{
                // fontSize: 18,
                padding: isMobile ?  '0px 30px' : '0px 300px',
                color: 'white',
                textAlign: 'left',
                // textIndent: !isMobile ?  '3em' : null
                // width: '70%',
              }}
            >
              Explore a selection of my past projects that showcase my skills and creativity as a developer. Each project reflects my commitment to quality, innovation, and user-centric design. From web applications to mobile solutions, I strive to create impactful experiences that address real-world challenges.
          </p>
          </div>
          

          </div>
          
        </div> 

        <div style={{  borderWidth: 0, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>
          <ProjectItem 
            title={'Nüri'} 
            description={'A cross-platform mobile app that connects Foodies to Home Cooks.'} 
            icon={FaReact}
            technologies={[
              {label: 'React Native', value: 'react_native'}, 
              {label: 'Node.js', value: 'node_js'},
              {label: 'MongoDB', value: 'mongodb'},
              {label: 'Firebase', value: 'firebase'},
              {label: 'Firebase', value: 'firebase'},
              {label: 'Firebase', value: 'firebase'},
            ]}
            sourceCodeAvailable={false}

            onClick={() => window.open('https://www.nurieats.com', '_blank')}
            url={''}
          />

          <ProjectItem 
            title={'nurieats.com'} 
            description={'The official website for the Nüri app, where food enthusiasts can connect with home cooks, explore menus, and order delicious homemade meals effortlessly.'} 
            icon={FaReact}
            technologies={[
              {label: 'React.js', value: 'react_js'},
              {label: 'Node.js', value: 'node_js'},
              {label: 'MongoDB', value: 'mongodb'},
              {label: 'Payload', value: 'payload'},
            ]}
            sourceCodeAvailable={false} 
            url={''}
            onClick={() => window.open('https://www.nurieats.com', '_blank')}
          />
          
          <ProjectItem 
            title={'jordanjancic.com'} 
            description={'A personal portfolio showcasing my projects, skills, and interests in web development. Explore my work with technologies like React.js and Ruby, and see how I create engaging and user-friendly web experiences. This site serves as a central hub for my professional journey and showcases my passion for coding and design.'} 
            icon={FaReact}
            technologies={[
              {label: 'React.js', value: 'react_js'},
              {label: 'Node.js', value: 'node_js'},
              {label: 'MongoDB', value: 'mongodb'},
              {label: 'Payload', value: 'payload'},
            ]}
            sourceCodeAvailable={true} 
            url={''}
            onClick={() => window.open('https://www.jordanjancic.com', '_blank')}
          />

          <ProjectItem 
            title={'Stock Alert'} 
            description={'Stock Alert is a program designed to monitor the Nike website for specific items and sizes, notifying users instantly when they are back in stock. With real-time updates, it helps sneaker enthusiasts and shoppers secure their desired products without the hassle of constant manual checks. Stay ahead of the game with instant stock alerts!'} 
            icon={FaPython}
            technologies={[
              {label: 'Python', value: 'python'}, 
   
            ]}
            sourceCodeAvailable={true}
            url={''}
            onClick={() => window.open('https://github.com/JordanJancic/StockBot', '_blank')}
          />

          <ProjectItem 
            title={'Fit4Lyfe'} 
            description={'Fit4Lyfe is a Python GUI application that utilizes Tkinter to streamline gym booking processes. Designed to combat the challenges of securing time slots during the pandemic, Fit4Lyfe allows users to effortlessly select and reserve their preferred gym times. With its automated service, users gain a competitive edge, ensuring they can secure their spots quickly and efficiently, even when demand is high.'} 
            icon={FaPython}
            technologies={[
              {label: 'Python', value: 'python'}, 
            ]}
            sourceCodeAvailable={true} 
            url={''}
            onClick={() => window.open('https://github.com/JordanJancic/f4l-gui', '_blank')}
          />
          
          <ProjectItem 
            title={'JavaFX-Paint-Clone'} 
            description={'A very simplified clone of our beloved MS Paint Program! One of the earlier projects I put together which I am proud of.'} 
            icon={FaJava}
            technologies={[
              {label: 'Java', value: 'java'},
            ]}
            sourceCodeAvailable={true} 
            url={''}
            onClick={() => window.open('https://github.com/JordanJancic/JavaFX-Paint-Clone', '_blank')}
          />
          
         
        </div>


        
        {/* <ProjectItem style={{width: '25%', margin: 10}} /> */}
      </GlassContainer>


      </div>
      

      <div
        style={{
          marginTop: 10,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          pointerEvents: "none",
          zIndex: 2,
          border: "0px solid GREEN",
        }}
      >

      </div>
      <div id={'about'} style={{width: '100%', display: 'flex', border: '0px solid white', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
      {newsArticles.map((article, index) => (
      
          <BasicNewsArticle 
            key={index}
            theme={theme}
            articleDate={article.articleDate}
            imageUrl={article.image.url} 
            imageCaption={article.imageCaption} 
            imageCaptionAuthor={article.imageCaptionAuthor}
            title={article.title} 
            subtitle={article.subtitle} 
            subtitleAuthor={article.subtitleAuthor} 
            articleBody={article.articleBody}
            articleAuthor={article.articleAuthor} 
          />
     
        
      ))}
      </div>
      <div style={{height: 10}}/>

      <div
        id={'contact'}
        style={{
          width: '100%',
          height: 400,
          display: 'flex',
          border: '0px solid white',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          marginTop: 40
        }}
      >
        <GlassContainer disabled={isMobile} style={{ display: "flex", flexDirection: 'column', alignItems: 'center', justifyContent: 'center',  }} theme={theme}>
          <h2
            style={{
              fontSize: 25,
              // color: 'black', // Set text color to black
              // userSelect: "none",
              // // textAlign: !isMobile ? 'left' : 'center',
              padding: !isMobile ? 30 : 0,
              // paddingLeft: !isMobile ? 70 : 0,
              paddingTop: !isMobile ? 50 : 20,
              color: 'white',
              letterSpacing: 10,
            }}
          >
            CONTACT
          </h2>
          <div style={{width: '100%', border: '0px solid white', display: 'flex', justifyContent: 'center', }}>
          <form style={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: 400,  }}>
            <input
              type="text"
              placeholder="Your Name"
              required
              style={{
                padding: 10,
                margin: 10,
                border: '1px solid #ccc',
                borderRadius: 5,
                outline: 'none',
                fontSize: 16,
                color: 'black', // Set input text color to black
              }}
            />
            <input
              type="email"
              placeholder="Your Email"
              required
              style={{
                padding: 10,
                margin: 10,
                border: '1px solid #ccc',
                borderRadius: 5,
                outline: 'none',
                fontSize: 16,
                color: 'black', // Set input text color to black
              }}
            />
            <textarea
              placeholder="Your Message"
              required
              rows="4"
              style={{
                padding: 10,
                margin: 10,
                border: '1px solid #ccc',
                borderRadius: 5,
                outline: 'none',
                fontSize: 16,
                color: 'black', // Set textarea text color to black
              }}
            />
            <button
              type="submit"
              style={{
                padding: 10,
                margin: 10,
                backgroundColor: theme === "light" ? "#5a59c9" : "#6e6ef4",
                color: 'white',
                border: 'none',
                borderRadius: 5,
                cursor: 'pointer',
                fontSize: 16,
                transition: 'background-color 0.3s',
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = theme === "light" ? "#6e6ef4" : "white"; // Darker shade on hover
                e.target.style.color = theme === "light" ? "#6e6ef4" : "black";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = theme === "light" ? "#6e6ef4" : "#6e6ef4"; // Revert to original color
                e.target.style.color = theme === "light" ? "#6e6ef4" : "white";
              }}
            >
              Send Message
            </button>
            <div style={{height: 50}}></div>
          </form>
          </div>
          
        </GlassContainer>
      </div>


      


      <div style={{height: 20}}/>

      <div style={{  position: 'fixed', bottom: 0, width: '100%', zIndex: 999 }}>
        {isMobile && (
          <BottomTabNavigator navigate={(value) => handleMoveToComponent(value)} icons={[FaHouseUser, FaProjectDiagram, FaSmile, FaPaperPlane]} theme={theme} handleToggleTheme={() => handleToggleTheme()} />
        )}
      </div>
      {!isMobile && (
        <><div style={{ height: 50 }}></div><Footer handleMoveToComponent={(value) => handleMoveToComponent(value)} /></>
      )
      ||
      (
        <><div style={{ height: 50 }}></div><Footer handleMoveToComponent={(value) => handleMoveToComponent(value)} /><div style={{ height: 80 }}></div></>
      )
      }
        

    </div>
  );
}

export default App;
