import React, { useState, useEffect } from "react";
import "../App.css";
import { BorderAllTwoTone, BorderRight } from "@mui/icons-material";

function BottomTabNavigator({ icons, theme, navigate }) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Adjust the threshold as needed

  const labels = ["Home", "Projects", "About", "Contact"];

  const handleNavigationAction = (index, event) => {
    // Access the clicked icon directly
    const iconWrapper = event.currentTarget;

    // Change icon size temporarily
    iconWrapper.style.transform = 'scale(1.2)';
    setTimeout(() => {
      iconWrapper.style.transform = 'scale(1)';
    }, 150);

    // Create and add ripple effect
    const ripple = document.createElement("span");
    ripple.classList.add("ripple");
    const size = Math.max(iconWrapper.offsetWidth, iconWrapper.offsetHeight);
    ripple.style.width = ripple.style.height = `${size}px`;
    ripple.style.left = `${event.clientX - iconWrapper.getBoundingClientRect().left - size / 2}px`;
    ripple.style.top = `${event.clientY - iconWrapper.getBoundingClientRect().top - size / 2}px`;
    iconWrapper.appendChild(ripple);
    
    // Remove ripple after animation
    ripple.addEventListener('animationend', () => {
      ripple.remove();
    });

    // Show tooltip with the label
    showTooltip(labels[index]);

    // Navigate to the respective section
    navigate(index);
  };

  const showTooltip = (label) => {
    const tooltip = document.createElement('div');
    tooltip.innerText = label;
    tooltip.className = 'tooltip';
    
    document.body.appendChild(tooltip);
    tooltip.style.left = `${window.innerWidth / 2 - tooltip.offsetWidth / 2}px`;
    tooltip.style.bottom = '80px'; // Position it above the tab navigator

    setTimeout(() => {
      tooltip.remove();
    }, 1000);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    // className="navbar-content frosted-glass-container-blur-plus" 
    <div style={styles.navigatorContainer}>
      <div style={styles.iconContainer}>
        {icons.map((Icon, index) => (
          <div onClick={(event) => handleNavigationAction(index, event)} key={index} style={styles.iconWrapper}>
            <Icon color={theme === 'dark' ? '#ccccff' : '#5a5ac9'} size={25} />
            <span style={styles.iconLabel}>{labels[index]}</span>
          </div>
        ))}
      </div>
    </div>
  );  
}

const styles = {
  navigatorContainer: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    zIndex: 999,
    padding: '10px 10px',
    boxShadow: '0 -2px 10px rgba(0,0,0,0.1)',
    backgroundColor: 'black',
    
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'space-around', // Distributes icons evenly with space between
    alignItems: 'center',
    width: '100%',
    
  },
  iconWrapper: {
    
    // border: '1px solid white',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative', // Required for ripple effect
    overflow: 'hidden',   // Prevents overflow of ripple effect
  },
  iconLabel: {
    marginTop: 5,
    fontSize: '12px', // Responsive size
    color: '#ccccff', // Adjust for theme if needed
    textAlign: 'center',
  },
};

// CSS for ripple effect and tooltip
const css = `
.ripple {
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.6);
  transform: scale(0);
  animation: ripple-animation 0.6s linear;
}

@keyframes ripple-animation {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

.tooltip {
  position: fixed;
  background: black;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  opacity: 0.8;
  z-index: 1000;
  transition: opacity 0.3s ease;
}
`;

// Add the styles to the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = css;
document.head.appendChild(styleSheet);

export default BottomTabNavigator;
