import axios from "axios";

// Fetch news articles with promise
export const fetchNewsArticles = () => {
  return axios
    .get("https://api.jordanjancic.com/api/getNewsArticles") // Hardcoding to test
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching news articles:", error);
      throw error;
    });
};
